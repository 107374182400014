import { render, staticRenderFns } from "./Marketing.vue?vue&type=template&id=061e929c&scoped=true"
import script from "./Marketing.vue?vue&type=script&lang=js"
export * from "./Marketing.vue?vue&type=script&lang=js"
import style0 from "./Marketing.vue?vue&type=style&index=0&id=061e929c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "061e929c",
  null
  
)

export default component.exports