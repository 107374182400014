<template>
<!--  TODO: 'ПЕРЕНЕСИ В ХЭДЕР'-->
  <TransitionFade>
    <div id="mobile-menu-wrapper" v-show="isMobileMenuOpen">
      <div id="mobile-menu">
        <div id="mobile-menu-header">
          <div
              id="mobile-close-button"
              @click="setIsMobileMenuOpen(false)"
          >
            <img
                src="@/assets/icons/close.svg"
                alt=""
            >
          </div>
          <div id="mobile-menu-header-title">
            <img src="@/assets/logo-partial.svg" alt="">
          </div>
        </div>
        <div id="mobile-menu-links">
          <MenuLinkMobile
              v-for="e in links"
              :key="e.text"
              :to="e.to"
              :goTo="goTo"
          >
            {{getTranslate(e.translateId)}}
          </MenuLinkMobile>
        </div>
        <div class="spacer"></div>
        <div id="mobile-menu-buttons">
          <!--  TODO: 'ЯЗЫК СВИТЧ'-->
          <div class="mobile-menu-lang-switch">
            <div
                v-if="lang === 'ru'"
                class="mobile-menu-lang-switch-item"
                @click="setLang('ru')"
                :class="{
                  'mobile-menu-lang-switch-item__active': lang === 'ru',
                }"
            >
              <img :src="ruIcon" alt="">
            </div>
            <div
                v-if="lang === 'en'"
                class="mobile-menu-lang-switch-item"
                @click="setLang('en')"
                :class="{
                  'mobile-menu-lang-switch-item__active': lang === 'en',
                }"
            >
              <img :src="enIcon" alt="">
            </div>
          </div>
          <a href="https://m.hotellab.io/">
            <BaseButton filled>
              {{getTranslate('buttonSignIn')}}
            </BaseButton>
          </a>
          <router-link :to="{name: 'Demo',}">
            <BaseButton
                filled
                class="header-buttons-demo"
                style="margin-top: 10px"
            >
              {{getTranslate('buttonForADemo')}}
            </BaseButton>
          </router-link>
        </div>
      </div>
      <div
          id="mobile-menu-background"
          @click="setIsMobileMenuOpen(false)"
      ></div>
    </div>
  </TransitionFade>
</template>

<script>
import BaseButton from "@/components/BaseButton";
import MenuLinkMobile from "@/components/MenuLinkMobile";
import TransitionFade from "@/components/TransitionFade";
import { links } from "@/ext.js";
import {mapActions, mapGetters} from "vuex";
import ruIcon from "@/assets/flag-ru.svg";
import enIcon from "@/assets/flag-uk.svg";

export default {
  name: "MenuMobile",
  computed: {
    ...mapGetters([
      'getTranslate',
      'lang'
    ]),
  },
  components: {
    BaseButton,
    MenuLinkMobile,
    TransitionFade,
  },
  props: {
    setIsMobileMenuOpen: {
      type: Function,
      required: true,
    },
    isMobileMenuOpen: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    links,
    ruIcon,
    enIcon,
  }),
  methods: {
    ...mapActions([
        'setLang',
    ]),
    goTo(link) {
      this.setIsMobileMenuOpen(false);
      this.$router.push(link);
    },
  },
}
</script>

<style lang="scss" scoped>
#mobile-menu-wrapper {
  position: fixed;
  z-index: 100;
  top: 0;
  width: 100%;
  height: 100%;

  #mobile-menu-background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .5);
    z-index: -1;
  }

  #mobile-menu {
    height: 100%;
    box-sizing: border-box;
    max-width: 400px;
    display: flex;
    position: relative;
    width: 70%;
    background-color: white;
    flex-direction: column;
    transform: translateX(300px);
    animation: SlideInLeft .3s 1 forwards;
    @keyframes SlideInLeft {
      0%{transform: translateX(-400px)}
      100%{transform: translate(0px)}
    }

    #mobile-menu-header {
      width: 100%;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 15px;

      #mobile-menu-header-title {
        margin-top: 2px;
        font-size: 30px;
        width: 50px;
        color: rgba(0,0,0, .1);
      }

      #mobile-close-button {
        width: 30px;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 100%;
        }
      }
    }

    .spacer {
      flex-grow: 1;
    }



    #mobile-menu-buttons {
      padding: 15px;

      .mobile-menu-lang-switch {
        display: flex;
        height: 40px;
        margin-bottom: 12px;
        border-radius: 5px;
        box-sizing: border-box;
        border: 1px solid $primary;

        &-item {
          width: 100%/*50%*/;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          transition: .3s;
          justify-content: center;

          &__active {
            background-color: $primary;
          }

          img {
            height: 20px;
            border-radius: 4px;
          }
        }
      }
    }
  }
}
</style>
