import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/Home.vue';
import Single from '@/views/Single.vue';
import Demo from '@/views/Demo.vue';
import AboutUs from '@/views/AboutUs.vue';
import AboutProgram from '@/views/AboutProgram.vue';
import Marketing from "@/views/Marketing";
import Revenue from "@/views/Revenue";
import Blog from "@/views/Blog";
import News from "@/views/News";
import Case from "@/views/Case";
import Contacts from "@/views/Contacts";
import store from '@/store';
import Solutions from "@/views/Solutions";
import Pricing from "@/views/Pricing";
import Content from "@/views/Content";
import NotFound from "@/views/NotFound";
import BaseMain from "@/components/BaseMain";
import Integrations from "@/views/Integrations";
import Webinar from "@/views/Webinar";
import WebinarFeb22 from "@/views/WebinarFeb22";
import WebinarApr22 from "@/views/WebinarApr22";
import WebinarApr22Krasnodar from "@/views/WebinarApr22Krasnodar";
import WebinarFeb22kurort from "@/views/WebinarFeb22kurort";
import WebinarRNSochi from "@/views/WebinarRNSochi";
import WebinarRNMoscow from "@/views/WebinarRNMoscow";
import WebinarRNSPB from "@/views/WebinarRNSPB";
import WebinarSPB from "@/views/WebinarSPB";
import WebinarJune26 from "@/views/WebinarJune26";
import WebinarAug22 from "@/views/WebinarAug22";
import WebinarNov10 from "@/views/WebinarNov10";
import WebinarMarch30 from "@/views/WebinarMarch30";
import WebinarJune21 from "@/views/WebinarJune21";
import WebinarOct23 from "@/views/WebinarOct23";
import WebinarApr24 from "@/views/WebinarApr24";
import WebinarJuly25 from "@/views/WebinarJuly25";
import RateShopper from "@/views/RateShopper";
import Benchmarking from "@/views/Benchmarking";
import OtaInsight from "@/views/OtaInsight";
import Simulator from "@/views/Simulator"

Vue.use(VueRouter)

const routes = [
  {
    path: '/:lang',
    name: 'Lang',
    component: BaseMain,
    children: [
      {
        path: '',
        name: 'Home',
        component: Home,
      },
      {
        path: 'home',
        name: 'Single',
        component: Single,
      },
      {
        path: 'integrations',
        name: 'Integrations',
        component: Integrations,
        meta: { slug: 'homeIntegrationsTitle', },
      },
      {
        path: 'demo',
        name: 'Demo',
        component: Demo,
        meta: { slug: 'buttonForADemo', },
      },
      {
        path: 'about-us',
        name: 'AboutUs',
        component: AboutUs,
        meta: { slug: 'linkAboutUs', },
      },
      {
        path: 'contacts',
        name: 'Contacts',
        component: Contacts,
        meta: { slug: 'linkContacts', },
      },
      {
        path: 'marketing',
        name: 'Marketing',
        component: Marketing,
        meta: { slug: 'linkMarketing', },
      },
      {
        path: 'revenue',
        name: 'Revenue',
        component: Revenue,
        meta: { slug: 'linkRevenue', },
      },
      {
        path: 'blog',
        name: 'Blog',
        component: Blog,
        meta: { slug: 'linkBlog', },
        props: {
          show: 'all',
        },
      },
      {
        path: 'blog',
        name: 'BlogNews',
        component: Blog,
        meta: { slug: 'linkNews', },
        props: {
          show: 'news',
        },
      },
      {
        path: 'blog',
        name: 'BlogCases',
        component: Blog,
        meta: { slug: 'linkCases', },
        props: {
          show: 'cases',
        },
      },
      {
        path: 'blog',
        name: 'BlogMaterials',
        component: Blog,
        meta: { slug: 'linkMaterials', },
        props: {
          show: 'materials',
        },
      },
      {
        path: 'rateShopper',
        name: 'RateShopper',
        component: RateShopper,
        meta: { slug: 'linkRateShopper', },
      },
      {
        path: 'benchmarking',
        name: 'Benchmarking',
        component: Benchmarking,
        meta: { slug: 'linkBenchmarking', },
      },
      {
        path: 'event',
        name: 'Webinar',
        component: Webinar,
        meta: { slug: 'linkWebinar', },
      },
      {
        path: 'wrw-feb22',
        name: 'WebinarFeb22',
        component: WebinarFeb22,
        meta: { slug: 'linkWebinarFeb22', },
      },
      {
        path: 'wrw-feb22-kurort',
        name: 'WebinarFeb17',
        component: WebinarFeb22kurort,
        meta: { slug: 'linkWebinarFeb22kurort', },
      },
      {
        path: 'wrw-apr22',
        name: 'WebinarApr22',
        component: WebinarApr22,
        meta: { slug: 'linkWebinarApr22', },
      },
      {
        path: 'wrw-june23',
        name: 'WebinarJune26',
        component: WebinarJune26,
        meta: { slug: 'linkWebinarJune26', },
      },
      {
        path: 'wrw-aug22',
        name: 'WebinarAug22',
        component: WebinarAug22,
        meta: { slug: 'linkWebinarAug22', },
      },
      {
        path: 'wrw-apr22-krasnodar',
        name: 'WebinarApr22Krasnodar',
        component: WebinarApr22Krasnodar,
        meta: { slug: 'linkWebinarApr22Krasnodar', },
      },
      {
        path: 'hrw_spb_msk',
        name: 'WebinarSPB',
        component: WebinarSPB,
        meta: { slug: 'linkWebinarSPB', },
      },
      {
        path: 'rn-sochi',
        name: 'WebinarRNSochi',
        component: WebinarRNSochi,
        meta: { slug: 'linkWebinarRNSochi', },
      },
      {
        path: 'rn-moscow',
        name: 'WebinarRNMoscow',
        component: WebinarRNMoscow,
        meta: { slug: 'linkWebinarRNMoscow', },
      },
      {
        path: 'rn-spb',
        name: 'WebinarRNSPB',
        component: WebinarRNSPB,
        meta: { slug: 'linkWebinarRNSPB', },
      },
      {
        path: 'wrw-nov10',
        name: 'WebinarNov10',
        component: WebinarNov10,
        meta: { slug: 'linkWebinarNov10', },
      },
      {
        path: 'tbilisi-workshop',
        name: 'WebinarMarch30',
        component: WebinarMarch30,
        meta: { slug: 'linkWebinarNov10', },
      },
      {
        path: 'online-course',
        name: 'WebinarJune21',
        component: WebinarJune21,
        meta: { slug: 'linkWebinarJune21', },
      },
      {
        path: 'wrw-oct23',
        name: 'WebinarOct23',
        component: WebinarOct23,
        meta: { slug: 'linkWebinarOct23', },
      },
      {
        path: 'wrw-apr24',
        name: 'WebinarApr24',
        component: WebinarApr24,
        meta: { slug: 'linkWebinarApr24', },
      },
      {
        path: 'wrw-july25',
        name: 'WebinarJuly25',
        component: WebinarJuly25,
        meta: { slug: 'linkWebinarJuly25', },
      },
      {
        path: 'solutions',
        name: 'Solutions',
        component: Solutions,
        meta: { slug: 'linkSolutions', },
      },
      {
        path: 'pricing',
        name: 'Pricing',
        component: Pricing,
        meta: { slug: 'linkPricing', },
      },
      {
        path: 'blog/:id',
        name: 'News',
        component: News,
        meta: { type: 'news', },
      },
      {
        path: 'case/:id',
        name: 'Case',
        component: Case,
        meta: { type: 'case', },
      },
      {
        path: 'content/:id',
        name: 'Content',
        component: Content,
        meta: { type: 'content', },
      },
      {
        path: 'predictive-demand-data',
        name: 'OtaInsight',
        component: OtaInsight,
        meta: { slug: 'linkOtaInsight'},
      },
      {
        path: 'simulator',
        name: 'Simulator',
        component: Simulator,
        meta: { slug: 'linkSimulator' },
      },
      {
        path: 'about-program',
        name: 'AboutProgram',
        component: AboutProgram,
        meta: { slug: 'linkProgram' },
      },
      {
        path: 'not-found',
        name: 'NotFound',
        component: NotFound,
      },
      {
        path: '*',
        beforeEnter: (to, from, next) => {
          next({name: 'NotFound',});
        }
      }
    ],
  },
  {
    path: '*',
    beforeEnter: (to, from, next) => {
      next({name: 'Lang', params: {lang: localStorage.lang || 'ru',},});
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  window.scrollTo(0,0);
  store.commit('SET_ROUTE', to);
  next();
})


export default router
