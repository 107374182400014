<template>
  <footer id="footer">
    <div id="footer-inner">
      <div id="footer-main">
        <div id="footer-main-info-and-links">
          <div id="footer-main-info" class="footer-main-section">
            <div id="footer-main-info-logo-and-contacts">
              <div id="footer-main-info-logotype">
                <img src="@/assets/images/logo-white-new.png" alt="logo">
              </div>
              <div id="footer-main-info-contacts">
                <a href="mailto:sales@hotellab.io" style="color: white;" class="footer-main-info-contacts-item">
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#ffffff" class="bi bi-mailbox" viewBox="0 0 16 16"><path d="M4 4a3 3 0 0 0-3 3v6h6V7a3 3 0 0 0-3-3zm0-1h8a4 4 0 0 1 4 4v6a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V7a4 4 0 0 1 4-4zm2.646 1A3.99 3.99 0 0 1 8 7v6h7V7a3 3 0 0 0-3-3H6.646z"/><path d="M11.793 8.5H9v-1h5a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.354-.146l-.853-.854zM5 7c0 .552-.448 0-1 0s-1 .552-1 0a1 1 0 0 1 2 0z"/></svg>
                  sales@hotellab.io
                </a>
                <a :href="`tel:${getTranslate('footerPhone')}`" style="color: white;"  class="footer-main-info-contacts-item">
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#ffffff" class="bi bi-telephone" viewBox="0 0 16 16">
                    <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
                  </svg>
                  {{ getTranslate('footerPhone') }}
                </a>
                <div class="footer-main-info-contacts-item" :style="{ alignItems: lang === 'ru' ? 'flex-start' : 'center'}">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 289.09 261.86" width="26px">
                    <g>
                      <path fill="#fff" d="m44.36,112.59c0-8.18-.01-15.86,0-23.54.01-5.43,2.22-7.66,7.65-7.67,7.99-.02,15.98,0,23.97,0,1.1,0,2.2,0,3.68,0,0-1.52,0-2.72,0-3.91,0-13.22,0-26.43,0-39.65,0-6.37,1.91-8.28,8.21-8.3,6.65-.01,13.3,0,20.3,0,.06-1.24.16-2.32.17-3.39.02-6.35-.03-12.7.02-19.06.04-4.72,2.31-7.04,6.98-7.04,26.54-.03,53.07-.03,79.61,0,4.74,0,6.88,2.21,6.93,7.03.06,6.25.02,12.5.02,18.75,0,1.1,0,2.2,0,3.72,6.03,0,11.73,0,17.43,0,10.58,0,11.3.72,11.3,11.32v50.1c0,1.12,0,2.23,0,3.12,5.31,1.52,10.52,2.61,15.42,4.5,22.54,8.68,37.77,30.99,37.78,55.12,0,7.63-2.67,14.65-5.65,21.53-7.39,17.07-17.39,32.68-27.19,48.4-4.66,7.47-9.41,14.88-14.1,22.33-.58.92-1.08,1.9-1.88,3.32,1.58,0,2.66,0,3.75,0,14.24,0,28.48-.02,42.72.01,4.69,0,7.4,2.14,7.6,5.81.2,3.8-2.35,6.41-6.52,6.45-7.27.07-14.55,0-21.82.03-38,.11-76,.28-114.01.31-37.19.04-74.38-.05-111.57-.11-9.32-.01-18.64-.13-27.96-.21-4.61-.04-7.21-2.27-7.2-6.11.02-3.78,2.69-6.12,7.25-6.16,6.33-.06,12.66-.02,19.36-.02v-4.18c0-41.19,0-82.37,0-123.56,0-7.16,1.74-8.91,8.88-8.91,2.84,0,5.69,0,8.87,0Zm168.68,136.27c-5.63-9.03-11.09-17.67-16.43-26.38-9.74-15.87-19.71-31.62-27.12-48.77-2.89-6.68-5.34-13.51-5.23-20.98.42-27.44,20.81-51.93,47.73-57.25,2.07-.41,4.16-.71,6.16-1.05v-52.5h-125.85v207.02h41.99l78.76-.1Zm11.01-5.56c7.92-12.61,15.91-24.65,23.18-37.1,7.21-12.35,13.99-24.98,20.35-37.79,2.25-4.54,3.76-9.97,3.8-15,.16-21.86-15.86-41.24-37.04-45.7-22.28-4.69-44.37,6.29-53.51,26.82-4.09,9.2-6.11,18.75-2.29,28.59,2,5.14,3.96,10.39,6.73,15.12,12.51,21.36,25.31,42.56,38.01,63.82.21.35.43.68.78,1.25ZM39.13,125.05v123.91h40.18v-123.91h-40.18ZM189.37,29.17V12.56h-68.57v16.61h68.57ZM56.77,112.37h22.5v-18.48h-22.5v18.48Z"/>
                      <path fill="#fff" d="m254.09,152.11c-.05,16.67-13.46,30.02-30.13,30-16.54-.02-30.12-13.65-30.05-30.16.07-16.51,13.73-30.1,30.22-30.05,16.58.05,30.01,13.6,29.96,30.22Zm-12.3-.17c-.03-9.82-7.97-17.76-17.76-17.75-9.71,0-17.78,8.04-17.83,17.77-.05,9.85,8.08,17.92,18,17.86,9.82-.06,17.62-7.99,17.59-17.87Z"/>
                    </g>
                  </svg>
                  <p v-if="lang === 'en'">Keemia th 4, Tallinn, Estonia</p>
                  <div v-if="lang === 'ru'" :style="{ display: 'flex', flexDirection: 'column'}">
                    <p>ООО “Хотэллаб”,<br/>Юр. адрес: 620043, Свердловская обл.,<br/>г. Екатеринбург, ул. Малогородская, 2 - 49</p>
                    <p>ИНН: 6658554383<br/>КПП: 665801001<br/>ОГРН: 1226600037619</p>
                    <p>Св-во о гос. рег. программ для ЭВМ<br/> № 2022685069 от 21.12.22</p>
                  </div>
                </div>
              </div>
            </div>
            <div id="footer-main-info-social">
              <a
                v-for="(link, idx) in socialLinks"
                :key="`socialLink${idx}`"
                :href="link.href"
              >
                <BaseIcon
                  color="currentColor"
                  :icon="link.icon"
                  :size="32"
                />
              </a>
            </div>
            <div class="widget" id="footer-main-info-widget">
              <img
                v-if="lang === 'en'"
                class="widget-logo"
                src="@/assets/ehrl.png"
                width="200"
                alt="logo ehrl">
              <img
                v-if="lang === 'en'"
                class="widget-logo"
                src="@/assets/sete.jpeg"
                width="100"
                alt="logo sete">
            </div>
          </div>
          <div class="spacer"></div>
          <div id="footer-main-links" class="footer-main-section">
            <router-link
                v-for="(e, idx) in footerLinks.links"
                :key="`footerLinks.links${idx}`"
                :to="e.to"
                class="footer-main-links-item"
            >
              {{getTranslate(e.translateId)}}
            </router-link>
            <!--<template v-for="(e, idx) in footerLinks.sublinks">
              <router-link
                v-if="getTranslate(e.translateId) !== '{{hidden}}'"
                :key="`footerLinks.sublinks${idx}`"
                :to="e.to"
                class="footer-main-links-item footer-main-links-item__subitem"
              >
                {{getTranslate(e.translateId)}}
              </router-link>
            </template>-->
          </div>
        </div>

        <div class="spacer"></div>
        <div id="footer-main-subscribe" class="footer-main-section">
          <div id="footer-main-subscribe-title">
            {{getTranslate('footerSubscribeTitle')}}
          </div>
          <div id="footer-hubspot" ref="footer-hubspot"></div>
        </div>
      </div>
      <div class="footer-main-bottom">
      <div id="footer-rights" v-html="footerCopyrightText"></div>
      <div v-if="lang === 'ru'">
        <router-link :to="{name: 'AboutProgram',}" class="footer-link-about">
          {{getTranslate('linkProgram')}}
        </router-link>
      </div>
      </div>
    </div>
  </footer>
</template>


<script>
import BaseButton from "@/components/BaseButton";
import {mapGetters} from "vuex";
import BaseIcon from "@/components/BaseIcon";

const socialLinkData = ({ link, icon }) => ({ href: link, icon })

export default {
  name: "BaseFooter",
  computed: {
    ...mapGetters([
        'getTranslate',
        'lang',
    ]),
    footerCopyrightText() {
      return `© ${this.getTranslate('footerCopyrightText')}`;
    },
    socialLinks() {
      switch (this.lang) {
        case 'ru':
          return [
            socialLinkData({
              link: 'https://t.me/hotellab_io',
              icon: 'telegram'
            }),
          ]
        default:
          return [
            socialLinkData({
              link: 'https://www.linkedin.com/company/hotellab-io',
              icon: 'linkedIn'
            }),
            socialLinkData({
              link: 'https://www.facebook.com/hotellab.io/',
              icon: 'facebook'
            }),
          ]
      }
    },
  },
  watch: {
    lang() {
      this.appendForm();
    },
  },
  mounted() {
    this.appendForm();
    document.querySelector(".widget").appendChild(document.getElementById("htr_widget_235_hoteltech-report"));
  },
  methods: {
    appendForm() {
      this.$refs["footer-hubspot"].innerHTML = '';
      let formId = this.lang === 'ru'
          ? "c519b7b3-84de-4b47-b9d0-14d18f9fdd7b"
          : "55fd5a03-484c-4eab-a8ba-6439f47fdb2c"
      const script = document.createElement("script");
      script.src="https://js.hsforms.net/forms/v2.js";
      document.body.appendChild(script);
      script.addEventListener("load", () => {
        if (window.hbspt) {
          window.hbspt.forms.create({
            region: "na1",
            portalId: "7975392",
            formId,
            target: '#footer-hubspot',
          });
        }
      })
    },
  },
  data: () => ({
    footerLinks: {
      links: [
        {
          to: {name: 'AboutUs',},
          translateId: 'linkAboutUs',
          text: 'About Us',
        },
        {
          to: {name: 'Contacts',},
          text: 'Contacts',
          translateId: 'linkContacts',
        },
        {
          to: {name: 'Blog',},
          translateId: 'linkBlog',
        },
        {
          to: {name: 'Solutions',},
          text: 'Solutions',
          translateId: 'linkSolutions',
        },
      ],
      sublinks: [
        {
          to: {name: 'Marketing',},
          text: 'Marketing',
          translateId: 'linkMarketing',
        },
        {
          to: {name: 'Revenue',},
          text: 'Revenue',
          translateId: 'linkRevenue',
        },
        {
          to: {name: 'RateShopper',},
          text: 'Rate Shopper',
          translateId: 'linkRateShopper',
        },
        {
          to: {name: 'Benchmarking',},
          text: 'Benchmarking',
          translateId: 'linkBenchmarking',
        },
      ],
    },
  }),
  components: {
    BaseButton,
    BaseIcon
  },
}
</script>


<style lang="scss" scoped>
.spacer {
  flex-grow: 1;
  height: 1px;

  @media (max-width: $breakpoint-tablet) {
    flex-grow: 0;
  }
}
#footer {
  flex-shrink: 0;
  flex-grow: 0;
  width: 100%;
  background-color: $secondary;
  box-sizing: border-box;
  padding: 90px 50px 70px;
  display: flex;
  color: white;
  transition: .3s;
  justify-content: center;

  @media (max-width: $breakpoint-desktop) {
    padding: 60px 30px 50px;

    @media (max-width: $breakpoint-tablet) {
      padding: 40px 15px 30px;
    }
  }

  .widget-logo {
    margin-right: 20px;
  }

  @media (max-width: $breakpoint-tablet) and (min-width: $breakpoint-mobile) {
    .widget-logo {
      margin-bottom: 20px;
    }
  }

  &-inner {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    max-width: $max-width;

    #footer-main {
      display: flex;
      justify-content: space-between;

      @media (max-width: $breakpoint-tablet-v) {
        flex-direction: column;
      }

      #footer-main-info-and-links {
        display: flex;
        flex-grow: 1;

        @media (max-width: $breakpoint-tablet) {
          flex-direction: column;

          @media (max-width: $breakpoint-tablet-v) {
            flex-direction: row;

            @media (max-width: $breakpoint-mobile) {
              flex-direction: column;
            }
          }
        }

        #footer-main-info {
          display: flex;
          /*flex-shrink: 0;*/
          flex-direction: column;

          @media (max-width: $breakpoint-tablet) {
            flex-direction: row;
            margin-bottom: 40px;

            @media (max-width: $breakpoint-tablet-v) {
              flex-direction: row;

              @media (max-width: $breakpoint-mobile) {
                flex-direction: column;
              }
            }
          }

          #footer-main-info-logo-and-contacts {
            @media (max-width: $breakpoint-tablet) {
              width: 50%;

              @media (max-width: $breakpoint-tablet-v) {
                width: max-content;
                margin-right: 50px;
              }
            }

            #footer-main-info-logotype {
              width: 223px;
              margin-top: -32px;
              transition: .3s;
              margin-bottom: 16px;

              @media (max-width: $breakpoint-desktop) {
                width: 180px;
                margin-top: -28px;

                @media (max-width: $breakpoint-tablet) {
                  width: 140px;
                  margin-top: -18px;
                }
              }

              img {
                width: 100%;
              }
            }

            #footer-main-info-contacts {
              display: flex;
              flex-direction: column;
              font-weight: 400;
              line-height: 21px;
              margin-bottom: 40px;

              @media (max-width: $breakpoint-tablet) {
                margin-bottom: 0;

                @media (max-width: $breakpoint-mobile) {
                  margin-bottom: 20px;
                }
              }

              .footer-main-info-contacts-item {
                display: flex;
                align-items: center;
                font-weight: 500;
                margin: 4px 0;

                p {
                  margin: 0;
                }

                p:not(:last-child) {
                  margin-bottom: 15px;
                }

                svg {
                  margin-right: 12px;
                  width: 26px;
                  height: 26px;
                }
              }
            }
          }

          &-social {
            display: flex;
            height: 32px;

            a {
              color: white;
            }

            svg {
              width: 32px;
              margin-right: 16px;
              border-radius: 50%;
            }
          }

          &-widget {
            display: flex;
            margin-top: 30px;

            @media (max-width: $breakpoint-tablet) {
              flex-wrap: wrap;
              margin-left: -95px;
              margin-top: 65px;
              
              @media (max-width: $breakpoint-tablet-v) {
                margin-left: -80px;

                @media (max-width: $breakpoint-mobile) {
                  margin-left: 0;
                  margin-top: 30px;
                }
              }
            }
          }
        }

        #footer-main-links {
          margin-left: 60px;
          width: 320px;
          height: 140px;
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;

          @media (max-width: $breakpoint-desktop) {
            width: 280px;

            @media (max-width: $breakpoint-tablet) {
              margin-left: 0;
              width: 100%;

              @media (max-width: $breakpoint-tablet-v) {
                /*width: 280px;*/
                margin-left: 40px;

                @media (max-width: $breakpoint-mobile) {
                  margin-left: 0;
                  width: 100%;
                  margin-bottom: 30px;
                }
              }
            }
          }

          .footer-main-links-item {
            height: 21px;
            font-size: 16px;
            margin-bottom: 14px;
            color: white;
            font-weight: 700;
            text-transform: uppercase;

            &__subitem {
              font-weight: 400;
              text-transform: none;
            }
          }
        }
      }

      #footer-main-subscribe {
        width: 381px;
        display: flex;
        flex-direction: column;

        @media (max-width: $breakpoint-tablet-v) {
          margin: 10px 0 30px;
          width: auto;

          @media (max-width: $breakpoint-mobile) {
            margin-bottom: 20px;
          }
        }

        &-title {
          font-weight: 700;
          text-transform: uppercase;
          letter-spacing: 0;
          margin-bottom: 28px;
        }

        #footer-main-subscribe-inputs {
          display: flex;
          flex-direction: column;

          @media (max-width: $breakpoint-tablet-v) {
            flex-direction: row;

            @media (max-width: $breakpoint-mobile) {
              flex-direction: column;
            }
          }

          .footer-main-subscribe-input {
            margin-bottom: 16px;
            width: 381px;
            box-sizing: border-box;
            height: 48px;
            border-radius: 5px;
            box-shadow: $shadow-footer;
            outline: none;
            border: none;
            font-family: 'HL-Roboto', sans-serif;
            font-size: 16px;
            padding: 0 20px;
            font-weight: 400;

            @media (max-width: $breakpoint-tablet-v) {
              width: 50%;
              margin-right: 10px;

              @media (max-width: $breakpoint-mobile) {
                width: 100%;
                margin-right: 0;
              }
            }

            &::placeholder {
              color: #25272B29;
            }
            &:last-child {
              margin-right: 0;
            }
          }
        }

        #footer-main-subscribe-agree-and-submit {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          @media (max-width: $breakpoint-tablet-v) {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            @media (max-width: $breakpoint-mobile) {
              flex-direction: column;
              justify-content: flex-start;
              align-items: flex-start;
            }
          }

          #footer-main-subscribe-agreement {
            font-weight: 400;
            margin-top: 9px;
            line-height: 21px;
            width: 271px;

            @media (max-width: $breakpoint-tablet-v) {
              flex-grow: 1;
              margin-top: 0;

              @media (max-width: $breakpoint-mobile) {
                width: 100%;
              }
            }
          }

          #footer-main-subscribe-submit {
            align-self: flex-start;
            font-weight: 400;
            margin-top: 14px;
            margin-bottom: 3px;           

            @media (max-width: $breakpoint-tablet-v) {
              margin-bottom: 0;
              margin-top: 0;
              margin-left: 10px;

              @media (max-width: $breakpoint-mobile) {
                margin-top: 14px;
                margin-left: 0;
              }
            }
          }
        }
      }
    }

    .footer-main-bottom {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }

    .footer-link-about {
      display: block;
      margin-top: 20px;
      margin-left: 20px;
      height: 21px;
      font-size: 16px;
      color: white;
      font-weight: 700;
      text-transform: uppercase;
      white-space: nowrap;
    }

    #footer-rights {
      margin-top: 20px;
      font-weight: 500;
      white-space: pre-wrap;
    }
  }
}
</style>
