<template>
  <div id="pricing">
    <div class="page-section-wrapper">
      <div class="page-section-inner">
        <div id="pricing-summary" class="pricing-section-title page-section-title-center">Select your hotellab pricing plan for better performance</div>
        <div class="pricing-grid">
          <div v-for="e in tariffs" :key="e.id" class="pricing-grid-item">
            <div v-if="e.id === 1" class="pricing-grid-item-badge">Most popular</div>
            <div class="pricing-grid-item-info">
              <div class="pricing-grid-item-desc-title">
                {{ e.title }}
              </div>
              <div class="pricing-grid-item-desc-text">
                {{ e.description }}
              </div>
            </div>
            <div class="pricing-grid-item-desc">
              <div class="pricing-grid-item-desc-price">
                from <span class="pricing-grid-item-desc-price-value">€{{e.generic.price_from}}</span> / month
              </div>
              <div class="pricing-grid-item-desc-more">
                <router-link :to="{name: 'Demo',}">
                  <BaseButton>
                    Select
                  </BaseButton>
                </router-link>
              </div>
              <div class="pricing-grid-item-desc-setup">
                Setup fee from €{{e.generic.setup_fee}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page-section-wrapper">
      <div class="page-section-inner">
        <div class="page-section-title-center">Compare the plans</div>

        <div
          v-for="(item) in [
            { id: 1, label: 'Included in pricing plan' },
            { id: 2, label: 'Can be added to pricing plan at an extra charge' },
          ]"
          :key="item.id"
          class="pricing-legend-compare">
          <div class="pricing-legend-compare-wrapper">
            <img
              width="30px"
              src="@/assets/icons/checkmark.svg"
              alt="checkmark" />
            <span v-if="item.id === 2" class="pricing-legend-compare-item-additional">+</span>
          </div>
          <span class="pricing-legend-compare-label">{{ item.label }}</span>
        </div>
      
        <div class="pricing-above-compare">
          <button
            class="pricing-above-compare-button"
            :class="{
              'pricing-above-compare-button__open': isCollapseAll,
            }"
            @click="changeVisibility('all', null, null, !isCollapseAll)">
            {{ isCollapseAll ? 'Collapse all' : 'Expand all' }}
          </button>
        </div>
        <div class="pricing-compare">
          <div class="pricing-compare-row">
            <div
              v-for="(e, ind) in ['Summary', 'Start', 'Pro', 'Enterprise']"
              :key="ind" class="pricing-compare-item pricing-compare-item-title">
              <a v-if="ind === 0" href="#pricing-summary" class="pricing-compare-item-title-link">
                {{ e }}
                <img
                  width="8px"
                  src="@/assets/icons/arrow-top.svg"
                  alt="top" />
              </a>
              <span v-else="ind !== 0">{{ e }}</span>
            </div>
          </div>

          <div v-for="(e, indE) in plansData" :key="e.id">
            <div class="pricing-compare-title"><span>{{ e.title }}</span></div>
            <template v-for="(el, indEl) in e.data">
              <div :key="el.id" class="pricing-compare-row">
                <div class="pricing-compare-item">
                  <button
                    v-if="el.data.length"
                    @click="changeVisibility('current', indE, indEl)"
                    class="pricing-compare-button"
                    :class="{
                      'pricing-compare-button__open': el.visible,
                    }" />
                  <span class="pricing-compare-item-subtitle">{{ el.subtitle }}</span>
                </div>
                <div
                  v-for="(item, indItem) in planItems"
                  :key="indItem"
                  class="pricing-compare-item">
                  {{ typeof(el[item]) !== 'boolean' ? el[item]: '' }}
                  <img
                    v-if="typeof(el[item]) === 'boolean' && el[item]"
                    width="30px"
                    src="@/assets/icons/checkmark.svg"
                    alt="checkmark" />
                  <span v-if="el[`${item}WithAdd`]" class="pricing-compare-item-additional">+</span>
                </div>
              </div>
              <div v-if="el.visible" v-for="subEl in el.data" :key="`${e.id}${el.id}${subEl.id}`" class="pricing-compare-row">
                <div class="pricing-compare-item">
                  <span class="pricing-compare-item-subsubtitle">{{ subEl.subsubtitle }}</span>
                </div>
                <div
                  v-for="(item, indItem) in planItems"
                  :key="indItem"
                  class="pricing-compare-item">
                  {{ typeof(subEl[item]) !== 'boolean' ? subEl[item] : '' }}
                  <img
                    v-if="typeof(subEl[item]) === 'boolean' && subEl[item]"
                    width="30px"
                    src="@/assets/icons/checkmark.svg"
                    alt="checkmark" />
                  <span v-if="subEl[`${item}WithAdd`]" class="pricing-compare-item-additional">+</span>
                </div>
              </div>
            </template>
            <button
              @click="changeVisibility('by_ind', indE, null, !checkCollapseInd(indE))"
              class="pricing-compare-button-more"
              :class="{
                'pricing-compare-button-more__open': checkCollapseInd(indE),
              }">
              {{ checkCollapseInd(indE) ? 'see less features': 'see more features' }}
            </button>
          </div>
        </div>

      </div>

    </div>
    <div class="bottom">
      <div class="bottom-demo">
        <router-link :to="{name: 'Demo',}">
          <BaseButton filled>
            {{getTranslate('buttonForADemo')}}
          </BaseButton>
        </router-link>
      </div>
      <div class="bottom-description">
        {{getTranslate('underButtonPhrase')}}
      </div>
    </div>
  </div>
</template>

<script>
import {news} from "@/ext";
import {cases} from "@/ext";
import BaseButton from "@/components/BaseButton";
import {mapGetters} from "vuex";

export default {
  name: "Pricing",
  props: {},
  computed: {
    ...mapGetters([
      'getTranslate',
      'getTranslateFromItem',
      'lang',
      'carousels',
      'trust',
      'images',
      'integrations',
      'news',
      'cases',
      'links'
    ]),
    isCollapseAll: function () {
      return this.plansData.map((plan, ind) => this.checkCollapseInd(ind)).filter((item) => item).length === this.plansData.length;
    },
  },
  data: () => ({
    planItems: [ "start", "pro", "enterprise" ],
    tariffs: [
      {
        id: 0,
        generic: {
          price_from: 200,
          setup_fee: 800,
        },
        title: "Hotellab: Start",
        description: "hotellab:START is tailored to the needs of small to mid-size city hotels with uncomplicated guest mix, short booking window and distinct competition",
      },
      {
        id: 1,
        generic: {
          price_from: 280,
          setup_fee: 1000,
        },
        title: "Hotellab: Pro",
        description: "hotellab:PRO is most optimal for hotels with diverse business mix that requires a more customized and adaptable algorithm to meet the unique needs of different guest segments and seasons",
      },
      {
        id: 2,
        generic: {
          price_from: 640,
          setup_fee: 1500,
        },
        title: "Hotellab: Enterprise",
        description: "hotellab:ENTERPRISE is designated for advanced hotels that prioritize the importance of NetRevPAR and GOPPAR. This encompasses the extensive yielding of rate products, usage of marketing and cost of sale data to maximize profitability",
      },
    ],
    plansData: [
      {
        id: 0,
        title: "Algorithm & Rate Updates",
        data: [
          {
            id: 0,
            subtitle: 'Pricing recommendations window',
            start: "365 days",
            pro: "365 days",
            enterprise: "365 days",
            data: []
          },
          {
            id: 1,
            subtitle: 'Frequency of recommendation updates',
            start: "2 per day",
            pro: "2 per day",
            enterprise: "2 per day",
            visible: false,
            data: [
              {
                id: 0,
                subsubtitle: 'Morning recommendations',
                start: "1-90 days out",
                pro: "1-180 days out",
                enterprise: "1-180 days out",
              },
              {
                id: 1,
                subsubtitle: 'Afternoon recommendations',
                start: "1-30 days out",
                pro: "1-60 days out",
                enterprise: "1-60 days out",
              },
              {
                id: 2,
                subsubtitle: 'Full year recommendations',
                start: "1-90 days out",
                pro: "1-180 days out",
                enterprise: "1-180 days out",
              }
            ]
          },
          {
            id: 2,
            subtitle: 'Hybrid autopilot rate updates',
            start: true,
            pro: true,
            enterprise: true,
            visible: false,
            data: [
              {
                id: 0,
                subsubtitle: 'Manually controlled by user',
                start: true,
                pro: true,
                enterprise: true,
              },
              {
                id: 1,
                subsubtitle: 'Hybrid controlled by user & autopilot',
                start: true,
                pro: true,
                enterprise: true,
              },
              {
                id: 2,
                subsubtitle: 'Fully controlled by autopilot',
                start: true,
                pro: true,
                enterprise: true,
              }
            ]
          },
          {
            id: 3,
            subtitle: 'Optimization of multiple rate products',
            start: false,
            pro: true,
            enterprise: true,
            data: []
          },
          {
            id: 4,
            subtitle: 'User-driven algorithm customization (over 100 factors)',
            start: false,
            pro: true,
            enterprise: true,
            visible: false,
            data: [
              {
                id: 0,
                subsubtitle: 'Rate rules by room category (floor/ceiling, dynamic supplements)',
                start: false,
                pro: true,
                enterprise: true,
              },
              {
                id: 1,
                subsubtitle: 'Occupancy vs. ADR driven strategy',
                start: false,
                pro: true,
                enterprise: true,
              },
              {
                id: 2,
                subsubtitle: 'Influence of competitors (by day of week)',
                start: false,
                pro: true,
                enterprise: true,
              },
              {
                id: 3,
                subsubtitle: 'Competitive price positioning adjustments',
                start: false,
                pro: true,
                enterprise: true,
              },
              {
                id: 4,
                subsubtitle: 'Pick-up, lead time, occupancy, rate change & availability adjustments',
                start: false,
                pro: true,
                enterprise: true,
              },
            ]
          },
          {
            id: 5,
            subtitle: 'Overbooking recommendations',
            start: true,
            pro: true,
            enterprise: true,
            visible: false,
            data: [
              {
                id: 0,
                subsubtitle: 'Oversell recommendations on room level',
                start: 'In progress',
                pro: 'In progress',
                enterprise: 'In progress',
              },
              {
                id: 1,
                subsubtitle: 'Fully customized upgrade path',
                start: 'In progress',
                pro: 'In progress',
                enterprise: 'In progress',
              },
              {
                id: 2,
                subsubtitle: 'Built-in capacity utilization & room efficiency algrothm',
                start: 'In progress',
                pro: 'In progress',
                enterprise: 'In progress',
              },
            ]
          },
        ]
      },
      {
        id: 1,
        title: "Rate Shopper",
        data: [
          {
            id: 0,
            subtitle: 'Shopping window',
            start: "365 days",
            pro: "365 days",
            enterprise: "365 days",
            data: []
          },
          {
            id: 1,
            subtitle: 'Frequency of competitive rate updates',
            start: "2 per day",
            pro: "2 per day",
            enterprise: "2 per day",
            visible: false,
            data: [
              {
                id: 0,
                subsubtitle: 'Morning shops',
                start: "1-90 days out",
                pro: "1-180 days out",
                enterprise: "1-180 days out",
              },
              {
                id: 1,
                subsubtitle: 'Afternoon shops',
                start: "1-30 days out",
                pro: "1-60 days out",
                enterprise: "1-60 days out",
              },
              {
                id: 2,
                subsubtitle: 'Full year shops',
                start: "monthly",
                pro: "weekly",
                enterprise: "weekly",
              }
            ]
          },
          {
            id: 2,
            subtitle: 'Number of competitors',
            start: 10,
            pro: 10,
            enterprise: 10,
            data: []
          },
          {
            id: 3,
            subtitle: 'Additional competitors',
            start: true,
            startWithAdd: true,
            pro: true,
            proWithAdd: true,
            enterprise: true,
            enterpriseWithAdd: true,
            data: []
          },
          {
            id: 4,
            subtitle: 'Shopped public rate products',
            start: "All",
            pro: "All",
            enterprise: "All",
            visible: false,
            data: [
              {
                id: 0,
                subsubtitle: 'Flexible / Unrestricted Room Only',
                start: true,
                pro: true,
                enterprise: true,
              },
              {
                id: 1,
                subsubtitle: 'Flexible / Unrestricted with Breakfast',
                start: true,
                pro: true,
                enterprise: true,
              },
              {
                id: 2,
                subsubtitle: 'Prepaid / Restricted Room Only',
                start: true,
                pro: true,
                enterprise: true,
              },
              {
                id: 3,
                subsubtitle: 'Prepaid / Restricted with Breakfast',
                start: true,
                pro: true,
                enterprise: true,
              },
              {
                id: 4,
                subsubtitle: 'Packages, Half-board, Full-board',
                start: true,
                pro: true,
                enterprise: true,
              },
              {
                id: 5,
                subsubtitle: 'Length of Stay Packages',
                start: true,
                pro: true,
                enterprise: true,
              }
            ]
          },
          {
            id: 5,
            subtitle: 'Shopped Number of Persons',
            start: '1 or 2 pax',
            pro: '1 or 2 pax',
            enterprise: '1 or 2 pax',
            data: []
          },
          {
            id: 6,
            subtitle: 'Regular LOS shops',
            start: '1 option of LOS 1-7',
            pro: '1 option of LOS 1-7',
            enterprise: '1 option of LOS 1-7',
            data: []
          },
          {
            id: 7,
            subtitle: 'Additional LOS shops',
            start: true,
            startWithAdd: true,
            pro: true,
            proWithAdd: true,
            enterprise: true,
            enterpriseWithAdd: true,
            data: []
          },
          {
            id: 8,
            subtitle: 'Competitive rate insights',
            start: true,
            pro: true,
            enterprise: true,
            visible: false,
            data: [
              {
                id: 0,
                subsubtitle: 'Competitor rate pricing patterns & forecast',
                start: true,
                pro: true,
                enterprise: true,
              },
              {
                id: 1,
                subsubtitle: 'Competitor median trends',
                start: true,
                pro: true,
                enterprise: true,
              },
              {
                id: 2,
                subsubtitle: 'Min / max competitive pricing range',
                start: true,
                pro: true,
                enterprise: true,
              },
            ]
          },
        ]
      },
      {
        id: 2,
        title: "Demand Analysis",
        data: [
          {
            id: 0,
            subtitle: 'Bookings and market demand index',
            start: true,
            pro: true,
            enterprise: true,
            data: [
              {
                id: 0,
                subsubtitle: 'Daily occupancy / availability by room',
                start: true,
                pro: true,
                enterprise: true,
              },
              {
                id: 1,
                subsubtitle: 'Daily demand index by room',
                start: true,
                pro: true,
                enterprise: true,
              },
              {
                id: 2,
                subsubtitle: 'High booking activity / demand dates',
                start: true,
                pro: true,
                enterprise: true,
              },
              {
                id: 3,
                subsubtitle: 'Market demand vs. last year',
                start: true,
                pro: true,
                enterprise: true,
              }
            ]
          },
          {
            id: 1,
            subtitle: 'Pace & pick-up reporting',
            start: "365 days",
            pro: "365 days",
            enterprise: "365 days",
            visible: false,
            data: [
              {
                id: 0,
                subsubtitle: 'Daily OTB, pickup & cancellation statistics',
                start: true,
                pro: true,
                enterprise: true,
              },
              {
                id: 1,
                subsubtitle: 'Booking activity by segment, channel, geo-source, room type, rate code',
                start: true,
                pro: true,
                enterprise: true,
              },
              {
                id: 2,
                subsubtitle: 'Comparison to last year, budget, forecast',
                start: true,
                pro: true,
                enterprise: true,
              }
            ]
          },
          {
            id: 2,
            subtitle: 'Market supply',
            start: true,
            pro: true,
            enterprise: true,
            data: []
          },
          {
            id: 3,
            subtitle: 'Market supply by Lighthouse integration (former OTA Insight)',
            start: true,
            startWithAdd: true,
            pro: true,
            proWithAdd: true,
            enterprise: true,
            enterpriseWithAdd: true,
            data: []
          },
          {
            id: 4,
            subtitle: 'Flight searches & conversion',
            start: "In progress",
            pro: "In progress",
            enterprise: "In progress",
            visible: false,
            data: [
              {
                id: 0,
                subsubtitle: 'Flight demand vs. hotel demand',
                start: "In progress",
                pro: "In progress",
                enterprise: "In progress",
              },
              {
                id: 1,
                subsubtitle: 'Most searched dates',
                start: "In progress",
                pro: "In progress",
                enterprise: "In progress",
              },
              {
                id: 2,
                subsubtitle: 'Top 10 feeder markets',
                start: "In progress",
                pro: "In progress",
                enterprise: "In progress",
              },
            ]
          },
        ]
      },
      {
        id: 3,
        title: "Business Intelligence & Forecasting",
        data: [
          {
            id: 0,
            subtitle: 'Fully automated rooms occupancy & revenue forecast',
            start: true,
            pro: true,
            enterprise: true,
            data: [
              {
                id: 0,
                subsubtitle: 'Morning update',
                start: '1-90 days out',
                pro: '1-180 days out',
                enterprise: '1-180 days out',
              },
              {
                id: 1,
                subsubtitle: 'Afternoon update',
                start: '1-30 days out',
                pro: '1-60 days out',
                enterprise: '1-60 days out',
              },
              {
                id: 2,
                subsubtitle: 'Full year update',
                start: 'monthly',
                pro: 'weekly',
                enterprise: 'weekly',
              },
            ]
          },
          {
            id: 1,
            subtitle: 'Rooms budget, commissions, cost of sale data',
            start: false,
            pro: true,
            enterprise: true,
            visible: false,
            data: []
          },
          {
            id: 2,
            subtitle: 'Rooms profit forecasting',
            start: false,
            pro: true,
            enterprise: true,
            data: []
          },
          {
            id: 3,
            subtitle: 'Strategic business mix analysis',
            start: true,
            pro: true,
            enterprise: true,
            data: [
              {
                id: 0,
                subsubtitle: 'Segment / channel / geo-source / room type / rate code contribution',
                start: true,
                pro: true,
                enterprise: true,
              },
              {
                id: 1,
                subsubtitle: 'Segment / channel / geo-source / room type / rate code by DOW',
                start: true,
                pro: true,
                enterprise: true,
              },
              {
                id: 2,
                subsubtitle: 'Comparison to last year / peak year / budget',
                start: true,
                pro: true,
                enterprise: true,
              },
            ]
          },
          {
            id: 4,
            subtitle: 'Reservation statistics',
            start: true,
            pro: true,
            enterprise: true,
            visible: false,
            data: [
              {
                id: 0,
                subsubtitle: 'Booking lead time',
                start: true,
                pro: true,
                enterprise: true,
              },
              {
                id: 1,
                subsubtitle: 'Cancellation rate',
                start: true,
                pro: true,
                enterprise: true,
              },
              {
                id: 2,
                subsubtitle: 'Average LOS',
                start: true,
                pro: true,
                enterprise: true,
              },
            ]
          },
        ]
      },
      {
        id: 4,
        title: "Additional features",
        data: [
          {
            id: 0,
            subtitle: 'Yield Management',
            start: true,
            startWithAdd: true,
            pro: true,
            proWithAdd: true,
            enterprise: true,
            data: [
              {
                id: 0,
                subsubtitle: 'Stay-pattern recommendations',
                start: true,
                startWithAdd: true,
                pro: true,
                proWithAdd: true,
                enterprise: true,
              },
              {
                id: 1,
                subsubtitle: 'Manual & automated stay-pattern controls',
                start: 'In progress',
                pro: 'In progress',
                enterprise: 'In progress',
              },
              {
                id: 2,
                subsubtitle: 'Yieldability controls with last room value/hurdle',
                start: 'In progress',
                pro: 'In progress',
                enterprise: 'In progress',
              },
            ]
          },
          {
            id: 1,
            subtitle: 'Group pricing & displacement analysis',
            start: true,
            startWithAdd: true,
            pro: true,
            proWithAdd: true,
            enterprise: true,
            enterpriseWithAdd: false,
            visible: false,
            data: [
              {
                id: 0,
                subsubtitle: 'Profit-based group and tour series analysis',
                start: true,
                startWithAdd: true,
                pro: true,
                proWithAdd: true,
                enterprise: true,
              },
              {
                id: 1,
                subsubtitle: 'Transient displacement analysis',
                start: true,
                startWithAdd: true,
                pro: true,
                proWithAdd: true,
                enterprise: true,
              },
              {
                id: 2,
                subsubtitle: 'Minimum acceptable group rate (MAR)',
                start: true,
                startWithAdd: true,
                pro: true,
                proWithAdd: true,
                enterprise: true,
              },
            ]
          },
          {
            id: 2,
            subtitle: 'End-to-end analytics',
            start: true,
            startWithAdd: true,
            pro: true,
            proWithAdd: true,
            enterprise: true,
            data: []
          },
        ]
      },
    ]
  }),
  methods: {
    changeVisibility(type, ind1, ind2, value) {
      const newPlansData = [ ...this.plansData ];

      switch (type) {
        case "current":
          newPlansData[ind1].data[ind2].visible = !newPlansData[ind1].data[ind2].visible;
          break;
        case "by_ind":
          this.plansData[ind1].data.map((item, ind) => newPlansData[ind1].data[ind].visible = value);
          break;
        default:
          this.plansData.map((plan) => plan.data.map((item, ind) => item.visible = value));  
      }
      return this.plansData = newPlansData;
    },
    checkCollapseInd: function (ind) {
      const currentItems = this.plansData[ind].data.filter((item) => item.data.length && item.visible);
      const totalItems = this.plansData[ind].data.filter((item) => item.data.length);

      return currentItems.length === totalItems.length;
    }
  },
  components: {
    BaseButton,
  },
}
</script>

<style lang="scss" scoped>

/* ----------------------------------------------
 * Generated by Animista on 2023-11-24 14:13:3
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation swing-in-top-fwd
 * ----------------------------------------
 */
 @-webkit-keyframes swing-in-top-fwd {
  0% {
    -webkit-transform: rotateX(-100deg);
            transform: rotateX(-100deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 1;
  }
}
@keyframes swing-in-top-fwd {
  0% {
    -webkit-transform: rotateX(-100deg);
            transform: rotateX(-100deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 1;
  }
}

.pricing-section-title {
  width: 70%;
  margin: auto;
  margin-bottom: 40px;
  color: #1AA7BC;
}
.pricing-grid-item .button {
  padding-left: 70px;
  padding-right: 70px;
  color: #5D5CA8;
  border: 1px solid #C4C4C4;
}

.pricing-grid-item:hover .button,
.pricing-grid-item:active .button {
  color: #fff;
  background-color: #5D5CA8;
}

.bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  &-demo {
    width: 210px;
    margin-top: -20px;
    margin-bottom: 10px;
  }

  &-description {
    font-weight: 500;
    width: 50%;
    align-items: center;
    font-size: 32px;
    text-align: center;
    line-height: 60px;
    padding: 30px 30px;

    @media (max-width: $breakpoint-tablet) {
      font-size: 20px;
      line-height: 28px;

      @media (max-width: $breakpoint-tablet-v) {
        font-size: 20px;
        line-height: 28px;

        @media (max-width: $breakpoint-mobile) {
          font-size: 20px;
          line-height: 28px;
        }
      }
    }
  }
}
.pricing-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media (max-width: $breakpoint-mobile) {
    flex-direction: column;
    flex-wrap: nowrap;
  }

  &-item {
    position: relative;
    width: 30%;
    height: 428px;
    color: black;
    margin: 25px 15px;
    padding: 17px;
    box-sizing: border-box;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    background-color: #F9FAFF;
    box-shadow: 0px 3px 13px #00000029;
    border-radius: 10px;

    @media (max-width: $breakpoint-desktop) {
      width: 40%;
      flex-direction: column;
      height: inherit;

      @media (max-width: $breakpoint-tablet-v) {
        width: 100%;
        margin-left: 0px;
        height: 300px;
        flex-direction: row;

        @media (max-width: $breakpoint-mobile) {
          flex-direction: column;
          height: auto;
        }
      }
    }

    &-badge {
      position: absolute;
      top: -28px;
      right: 5%;
      width: 90%;
      padding: 4px 0;
      color: #fff;
      font-size: 14px;
      line-height: 19px;
      text-align: center;
      background-color: #1AA7BC;
    }

    &-info {
      flex-shrink: 0;
      width: 100%;
      height: 200px;
      border-radius: 8px;
      box-sizing: border-box;
      position: relative;

      @media (max-width: $breakpoint-desktop) {
        height: 200px;
        width: 100%;

        @media (max-width: $breakpoint-tablet-v) {
          width: 40%;
          height: 100%;

          @media (max-width: $breakpoint-mobile) {
            height: 150px;
            width: 100%;
          }
        }
      }
    }

    &-desc {
      flex-grow: 1;
      display: flex;
      box-sizing: border-box;
      flex-direction: column;
      justify-content: flex-end;

      @media (max-width: $breakpoint-tablet-v) {
        justify-content: flex-start;
      }

      &-title {
        margin-bottom: 24px;
        color: #5D5CA8;
        font-weight: 500;
        font-size: 32px;
        line-height: 43px;
        text-align: center;

        @media (max-width: $breakpoint-tablet) {
          font-size: 20px;
          line-height: 28px;

          @media (max-width: $breakpoint-tablet-v) {
            font-size: 20px;
            line-height: 28px;

            @media (max-width: $breakpoint-mobile) {
              font-size: 20px;
              line-height: 28px;
            }
          }
        }
      }

      &-text {
        display: -webkit-box;
        margin-top: 10px;
      }

      &-button {
        align-self: flex-end;
      }

      &-price {
        align-self: center;
        font-size: 18px;
        margin-top: 15px;
        margin-bottom: 24px;
        line-height: 24px;

        &-value {
          color: #1AA7BC;
          font-size: 24px;
          line-height: 32px;
          font-weight: 700;
        }
      }

      &-setup {
        color: #797E88;
        text-align: center;
      }

      &-more {
        align-self: center;
        font-weight: 500;
        font-size: 16px;
        bottom: 0;
        margin-bottom: 40px;
        color: rgba(127,126,186,255);

        @media (max-width: $breakpoint-desktop) {
          padding-top: 20px;

          @media (max-width: $breakpoint-tablet-v) {
            margin-bottom: 15px;

            @media (max-width: $breakpoint-mobile) {
              /*visibility: hidden;*/
            }
          }
        }
      }
    }
  }

  &-item:nth-child(2) {
    border: 1px solid #1AA7BC;
  }
}

.pricing-legend-compare {
  display: flex;
  align-items: center;

  @media (max-width: $breakpoint-tablet-v) {
    line-height: 28px;
      
    @media (max-width: $breakpoint-mobile) {
      font-size: 12px;
      line-height: 20px;
    }
  }

  &-wrapper {
    display: flex;
    align-items: center;
    width: 50px;
  }

  &-item-additional {
    color: #5D5CA8;
    font-size: 24px;
    font-weight: 400;
  }
}

.pricing-above-compare {
  position: relative;
  display: flex;
  justify-content: end;
  margin-bottom: 10px;

  &-button {
    margin-right: 30px;
    font-size: 16px;
    color: #C4C4C4;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
  &-button::before {
    content: "";
    position: absolute;
    right: 20px;
    bottom: 5px;
    width: 5px;
    height: 5px;
    border-right: 2px solid #C4C4C4;
    border-bottom: 2px solid #C4C4C4;
    transform: rotate(45deg);
    transition: all 0.5s ease-in-out;
  }
  &-button::after {
    content: "";
    position: absolute;
    right: 20px;
    bottom: 9px;
    width: 5px;
    height: 5px;
    border-left: 2px solid #C4C4C4;
    border-top: 2px solid #C4C4C4;
    transform: rotate(45deg);
    transition: all 0.5s ease-in-out;
  }
  &-button__open::before {
    bottom: 13px;
  }
  &-button__open::after {
    bottom: 2px;
  }
}
.pricing-compare {
  &-row {
    display: flex;
    font-weight: 500;
    -webkit-animation: swing-in-top-fwd 0.8s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
	        animation: swing-in-top-fwd 0.8s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
  }
  &-row:nth-child(even) {
    background-color: #F9FAFF;
  }
  &-title {
    color: #5D5CA8;
    font-size: 20px;
    line-height: 26px;
    font-weight: 500;
    
    @media (max-width: $breakpoint-tablet-v) {
      font-size: 18px;
        
      @media (max-width: $breakpoint-mobile) {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
  &-item {
    position: relative;
    width: 20%;
    display: flex;
    line-height: 32px;
 
    @media (max-width: $breakpoint-tablet-v) {
      line-height: 28px;
        
      @media (max-width: $breakpoint-mobile) {
        font-size: 12px;
        line-height: 20px;
      }
    }

    &-title {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      color: #5D5CA8;
      font-size: 20px;
      line-height: 26px;
        
      @media (max-width: $breakpoint-tablet-v) {
        font-size: 18px;
          
        @media (max-width: $breakpoint-mobile) {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
    &-title-link {
      color: black;
    }
    &-subtitle {
      display: flex;
      align-items: center;
      padding-left: 20px;
    }
    &-subsubtitle {
      display: flex;
      align-items: center;
      padding-left: 40px;
      font-weight: 400;
    }
    &-additional {
      position: absolute;
      margin-left: 40px;
      color: #5D5CA8;
      font-size: 24px;
      font-weight: 400;
    }
  }
  &-item:first-child {
    width: 40%;
  }
  &-item:not(:first-child) {
    justify-content: center;
    align-items: center;
  }
  &-button {
    position: absolute;
    width: 25px;
    height: 25px;
    left: -10px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    &-more {
      position: relative;
      margin-bottom: 10px;
      color: #C4C4C4;
      font-size: 14px;
      line-height: 19px;
      background-color: transparent;
      border: none;
      cursor: pointer;
    }
    &-more::before {
      content: "";
      position: absolute;
      right: -7px;
      bottom: 7px;
      width: 5px;
      height: 5px;
      border-right: 2px solid #C4C4C4;
      border-bottom: 2px solid #C4C4C4;
      transform: rotate(45deg);
    }
    &-more__open::before {
      transform: rotate(225deg);
    }
  }
  &-button::before {
    content: "";
    position: absolute;
    right: 7px;
    bottom: 5px;
    width: 8px;
    height: 8px;
    border-right: 2px solid #2F3238;
    border-bottom: 2px solid #2F3238;
    transform: rotate(45deg);
    transition: all 0.2s ease-in-out;

    @media (max-width: $breakpoint-mobile) {
      bottom: 7px;
    }
  }
  &-button__open::before {
    transform: rotate(225deg);
  }
}
</style>
